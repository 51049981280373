<template>
  <div class="login">
    <div class="flex items-center flex-col">
      <div class="formContainer">
        <div class="formTop flex items-center flex-col">
          <img src="/images/logo-dark.png" class="mb-15 w-130" alt="Logo" />
          <h2>Login In To Your Account</h2>
        </div>
        <div class="formBody">
          <form :class="{ disabled: isLoging }">
            <div class="mb-25 position-r input-group">
              <label class="lineLable">Email</label>
              <input
                type="email"
                v-model="email"
                required
                class="fullWidth"
                :class="{ error: !email_valid }"
              />
              <span class="validation" v-if="!email_valid"
                >Please enter your email</span
              >
            </div>

            <div class="mb-15 position-r input-group">
              <label>Password</label>
              <input
                :type="pswdType"
                v-model="password"
                required
                class="w-full"
                :class="{ error: !email_valid }"
              />
              <span
                class="icon fixRight iconBtn cursor-pointer"
                @click="togglePassword"
                ><font-awesome-icon
                  :icon="
                    password.length > 0 && !pswdVisible ? 'eye' : 'eye-slash'
                  "
              /></span>
              <span class="validation" v-if="!password_valid"
                >Please enter your password</span
              >
            </div>
            <div class="field flex justify-between items-center">
              <a-checkbox v-model="remember"> Remember me </a-checkbox>
              <router-link :to="{ name: 'forgot-password' }" class="sm-text"
                >Forgot Password</router-link
              >
            </div>

            <div class="failBG sm-text" v-if="msg !== ''" v-html="msg"></div>

            <a-button
              type="primary"
              class="h-50 w-full"
              @click.prevent="userLogin"
            >
              LOGIN
            </a-button>
            <div class="formBody__sso">
              <span>or</span>
              <a
                class="
                  button button--microsoft
                  h-50
                  flex
                  items-center
                  justify-center
                "
                :href="microsoftLoginURL"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
                  <path fill="#f35325" d="M0 0h10v10H0z" />
                  <path fill="#81bc06" d="M11 0h10v10H11z" />
                  <path fill="#05a6f0" d="M0 11h10v10H0z" />
                  <path fill="#ffba08" d="M11 11h10v10H11z" />
                </svg>
                LOGIN WITH MICROSOFT</a
              >
            </div>
            <div
              class="failBG failBG--sso sm-text"
              v-if="ssoErrorMessages[ssoError]"
            >
              {{ ssoErrorMessages[ssoError] }}
            </div>
            <p class="formBody__signup">
              No account?
              <router-link :to="{ name: 'signup' }" class="blueTextLink"
                >Sign up now.</router-link
              >
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import { bus } from "@/main.js";
import { mutations } from "@/utils/store.js";
export default {
  data() {
    return {
      pswdVisible: false,
      password: "",
      email: "",
      password_valid: true,
      email_valid: true,
      msg: "",
      remember: false,
      isLoging: false,
      microsoftLoginURL: "",
      ssoError: null,
      ssoErrorMessages: [
        "Something went wrong (Database error)",
        "Invalid tenant Id or tenant Id does not exists",
        "Unauthorized request from Microsoft.",
      ],
      userID: null,
      userAccountType: null,
    };
  },
  computed: {
    pswdType() {
      return this.pswdVisible ? "text" : "password";
    },
  },
  methods: {
    togglePassword() {
      this.pswdVisible = !this.pswdVisible;
    },
    userLogin() {
      this.password_valid = this.password.length > 0 ? true : false;
      this.email_valid = this.email.length > 0 ? true : false;

      if (this.password_valid && this.email_valid) {
        this.isLoging = true;
        const loginPromise = new Promise((resolve) => {
          wsUtils.UserLogin(resolve, {
            email: this.email,
            password: this.password,
          });
        });
        loginPromise.then((data) => {
          this.isLoging = false;
          if (
            data.AccountType !== null &&
            data.firstName !== null &&
            data.UserType !== null &&
            data.AccountName !== null &&
            data.AccountPaymentArrangement !== null
          ) {
            this.userID = data.userID;
            this.userAccountType = data.UserType;
            this.loginComplete(data);
          } else {
            this.$router.push({
              name: "global-error-page",
              params: { id: "WS1UL_01", userId: data.EncrypteduserID },
            });
          }
        });
      }
    },
    loginComplete(data) {
      if (data.userID !== 0) {
        // window.studioUser = data;

        this.setSkin(data.SkinIdentifier);

        if (this.remember) {
          jsUtils.setCookie("HS-STUDIO", JSON.stringify(data), 3);
        } else {
          jsUtils.setCookie("HS-STUDIO", JSON.stringify(data), 0.5);
        }

        jsUtils.deleteCookie("survey-type");

        localStorage.setItem("studioUser", JSON.stringify(data));

        let userType = data.AccountType.toLowerCase();

        setTimeout(() => {
          bus.$emit("login");
          let toRouterPath = jsUtils.getToRouterPath();

          if (toRouterPath !== "undefined") {
            this.$router.push(toRouterPath).catch(() => {
              this.userRedirection(userType);
            });
          } else {
            this.userRedirection(userType);
          }
          localStorage.removeItem("toRouterPath");
        }, 200);
      } else {
        if (data.Status === 2) {
          this.msg =
            "Before you can log in, your account needs to be confirmed. Please check your inbox for instructions on how to complete the registration process. If you can't find the email in your Inbox, please check your Junk/SPAM folder or contact support for assistance.";
        } else if (data.Status === 3 || data.Status === 0) {
          this.msg =
            '<p>The email or password you entered did not match our records. Please double-check and try again.</p><p>If you can\'t remember your password, you can use the "Forgot password" function to reset your password.</p>';
        }
      }
    },
    userRedirection() {
      if (this.userAccountType === "Guest") {
        this.createPackTestProject(93);
        return;
      }
      this.$router.push("/");
    },
    setSkin(skinClass) {
      if (skinClass !== "") {
        setTimeout(() => {
          document.body.classList.add(skinClass.toLowerCase(), "login");
        }, 200);
      }
    },
    createPackTestProject(typeId) {
      mutations.setSurveyPrice(typeId, "SurveyType");
      const createSurvey = new Promise((resolve) => {
        wsUtils.InsertSurveyPacktest(
          {
            SurveyTypeId: typeId,
            ProjectID: 8,
            UserID: this.userID,
          },
          resolve
        );
      });
      createSurvey.then((data) => {
        let surveyPriceSetData = {
          SurveyId: data.SurveyId,
          SurveyType: typeId,
          ClickSpex: true,
          Stims: [],
          Question: [],
          ReportOption: "Automated",
          Audience: {
            sampleSize: 200,
            categoryUser: 0,
            genPop: 1,
          },
        };

        let packTestPriceSetData = {
          surveyId: data.SurveyId,
          explicitSamplePerDeepDive: 100,
          legs: 1,
          ReportOption: "Automated",
          implicitComponent: true,
          deepDive: 1,
          compDeepDive: 0,
          comObjects: 1,
        };

        jsUtils.setSurveyTypeId(typeId);
        jsUtils.setSurveyPrice(surveyPriceSetData);
        jsUtils.setSurveyUnEncryptId(data.UnEncryptSId);
        jsUtils.setRunSurveyMode(false);
        jsUtils.setPackTestPrice(packTestPriceSetData);

        mutations.setSurveyPrice(data.SurveyId, "SurveyId");
        this.$router.push({
          name: "pack-test-setting",
          params: { id: data.SurveyId },
        });
      });
    },
  },
  mounted() {
    jsUtils.clearSetting();

    let account = jsUtils.readCookie("HS-STUDIO");
    if (account) {
      bus.$emit("login");
      this.$router.push("/");
    }
    this.ssoError = jsUtils.readCookie("SSOError");

    if (this.ssoError !== null) {
      jsUtils.deleteCookie("SSOError");
    }
  },
  created() {
    if (window.location.hostname === "app.hotspexlabs.com") {
      this.microsoftLoginURL =
        "http://sso.hotspexlabs.com/?ssoaction=login&appid=azure-ad";
    } else {
      this.microsoftLoginURL =
        "http://sso.hotspexlabs.com/?ssoaction=login&appid=azure-ad";
    }
    jsUtils.loadLangObj("videoAuthorLang", "/tooltip/videoAuthor.json");
  },
  activated() {
    document.body.classList.remove("login");
  },
};
</script>

<style lang="scss" scoped>
.login {
  background: var(
    --button-gradient,
    linear-gradient(99deg, #fb3b51 -11.27%, #a14be7 109.61%)
  );
  padding: 40px 20px;
  height: 100%;
}

form {
  margin: 25px 0;
}

form.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.failBG {
  margin-bottom: 30px;
  color: red;
  padding: 10px;
  border-radius: 5px;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  &--sso {
    margin: 15px 0 0;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
</style>
